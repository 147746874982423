import Cookies from 'js-cookie'

const USER_ID = 'user_id'
const USER_NAME = 'user_name'
const USER_MOBILE = 'user_mobile'
const USER_TYPE = 'user_type'
const USER_GIVEN_NAME = 'user_given_name'
const USER_LAST_NAME = 'user_last_name'

export function setUserLastName(last_name){
    Cookies.set(USER_LAST_NAME, last_name)
}

export function getUserLastName(){
    return Cookies.get(USER_LAST_NAME)
}

export function setUserGivenName(given_name){
    Cookies.set(USER_GIVEN_NAME, given_name)
}
export function getUserGivenName(){
    return Cookies.get(USER_GIVEN_NAME)
}

export function setUserType(type) {
    Cookies.set(USER_TYPE, type)
}
// student || educator
export function getUserType() {
    return Cookies.get(USER_TYPE)
}

export function setUserMobile(user_mobile) {
    Cookies.set(USER_MOBILE, user_mobile)
}

export function getUserMobile() {
    return Cookies.get(USER_MOBILE)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}

export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function clearUser() {
    Cookies.remove(USER_ID)
    Cookies.remove(USER_NAME)
    Cookies.remove(USER_MOBILE)
    Cookies.remove(USER_TYPE)
}