<template>
  <div id="app" class="root-content" >
    <div v-if="isNotHomePageView">
      <div class="lecture-top">
        <div class="lecture-top-content">
          <ul class="lecture-navbar-ul">
<!--            <div v-if="!isLogin()" class="lecture-nav-item" :style="createNavItemStyle('register')">-->
<!--              <li @click="goRegister">注册</li>-->
<!--            </div>-->
            <div v-if="!isLogin()" class="lecture-nav-item" :style="createNavItemStyle('login')">
              <li @click="goLogin">Sign in</li>
            </div>
            <div v-if="isLogin()" class="lecture-nav-item" style="font-family: Ubuntu-Medium">
              <li @click="logOut">Logout</li>
            </div>
          </ul>
          <div>
            <img class="top_logo" src="./assets/home_top_logo.png"  @click="goHome">
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;">
      <router-view/>
    </div>
    <!--    <div class="go-footer-home">-->
    <!--      <div style="width: 100%; left: 50%; top: 50%;position: absolute;transform:translateX(-50%) translateY(-50%)">-->
    <!--        <div>Gocademy 版权所有 © 2021 | contact@gocademy.cn ｜ www.gocademy.cn｜ 学析教育科技（上海）有限公司｜ 沪ICP备2021027051号-1-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import {clearUser, getUserId} from "@/utils/store";
import './assets/common/font.css'
export default {
  name: 'App',
  components: {
    HelloWorld
  },
  computed: {
    isNotHomePageView() {
      var path = this.$route.path
      var isHomePage = true
      if (path === '/') {
        isHomePage = false
      }
      return isHomePage
    },
  },
  methods: {
    createNavItemStyle(type) {
      var style = {}
      var path = this.$route.path
      if (path.indexOf(type) > 0) {
        style.color = '#F0B45A'
        style.backgroundColor = '#ffffff'
      } else {
        style.color = '#fff'
        style.backgroundColor = 'rgb(0,0,0,0)'
      }
      style.fontFamily='Ubuntu-Medium'
      return style
    },
    isLogin() {
      var user_id = getUserId()
      if (user_id) {
        return true
      }
      return false
    },
    goHome() {
      this.$router.push('/')
    },
    goRegister() {
      this.$router.push('/register')
    },
    goLogin() {
      this.$router.push('/login')
    },
    logOut() {
      clearUser()
      this.goLogin()
    },
  }
}
</script>

<style>

::-webkit-scrollbar {
  display: none;
}

@import "assets/common/common.css";

.root-content {
  width: 100%;
  height: 100%;
}

.top_logo {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 141px;
  height: auto;
}

.go-footer-home {
  text-align: center;
  bottom: 0;
  height: 5vh;
  position: fixed;
  width: 100%;
  background-color: #525252;
  font-size: 0.4vw;
  color: #CBCBCB;
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
}

.register-top-name {
  font-family: "Microsoft YaHei";
  color: #fff;
  font-size: 1.6vw;
  font-weight: bold;
}

#app {
  width: 100%;
  height: 100%;
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
