<template>
  <div class="root-container">
    <div style="width: 1200px;margin: 0 auto;">
      <div style="display: flex;flex-direction: row-reverse">
        <p v-if="!isLogin()" @click="goLogin" class="nav-title" style="margin-left: 36px; font-family: Ubuntu-Medium;">Login</p>
        <p v-if="isLogin()" @click="goHome" class="nav-title" style="font-family: Ubuntu-Medium;">Home</p>
      </div>
    </div>
    <div style="margin-top: 100px;margin-left: 10.816vw">
      <img src="../../assets/root_logo.png" style="width: 292px;height: auto">
      <p class="info_text" style="margin-top: 53px">
        ILoveIEO.com is a third-party courtesy initiative created by China <br>
        Economics Olympiad for students and advisers all over the world who are<br>
        interested in economics olympiad activities.
      </p>
      <p class="info_text" style="margin-top: 20px">
        It aims to provide and share useful tools, resources, materials, and learning <br>
        opportunities among economics olympiad enthusiasts.
      </p>
      <p class="info_text" style="margin-top: 20px">
        This site is being continuously updated and managed. Feel free to contact<br>
        us if you have any questions.
      </p>

      <div style="margin-top: 64px;display: flex;flex-direction: row;height: 64px;align-items: center">
        <div style="position: relative">
          <p class="subscribe1">Subscribe our newsletter</p>
        </div>
        <!--        <div-->
        <!--            style="height: 100%;align-items: center;display: flex;flex-direction: row;position: relative;margin-left: 80px;background-color: #ffffff;border-radius: 4px;width: 386px">-->
        <!--&lt;!&ndash;          <img src="../../assets/icon_search.png" style="width: 30px;height: 30px;margin-left: 20px">&ndash;&gt;-->
        <!--          <input-->
        <!--              v-model="subscribe_text"-->
        <!--              style="border-radius: 4px;margin-left: 10px;padding: 0;border: 0px solid transparent;outline: none;width: 100%;height: 100%"></input>-->
        <!--        </div>-->
        <p class="btn-subscribe" style="margin-left: 100px" @click="showSubscribeInfoDialog=true">
          Subscribe</p>
      </div>
      <p style="color: white;font-size: 18px;font-weight: 500;  font-family: Ubuntu-Medium;">get informed about our
        latest resource updates</p>


      <div style="margin-top: 150px;display: flex;flex-direction: row">
        <!--         <div style="display: flex;flex-direction: row;align-items: center;justify-content: center">
                  <img src="../../assets/icon_phone.png" style="width: 20px;height: auto">
                  <p style="margin-left: 20px;padding: 0;color: #F0B45A;font-size: 20px;">021-XXXXXXXX</p>
                </div>
         -->
        <!--  <div style="margin-left: 85px;display: flex;flex-direction: row;align-items: center;justify-content: center">
           <img src="../../assets/icon_email.png" style="width: 20px;height: auto">
           <p style="margin-left: 20px;padding: 0;color: #F0B45A;font-size: 20px;">contact@econ-olympiad.org</p>
         </div> -->

        <!-- <div style="margin-left: 85px;display: flex;flex-direction: row;align-items: center;justify-content: center">
          <img src="../../assets/icon_nav.png" style="width: 17px;height: auto">
          <p style="margin-left: 20px;padding: 0;color: #F0B45A;font-size: 20px;">上海市静安区开开大厦20k</p>
        </div> -->
      </div>
    </div>
    <div class="footer">
      <p style="color: #CBCBCB;font-size: 12px">Copyrights iLoveIEO.com © 2022 | www.iloveieo.com |
        contact@econ-olympiad.org</p>
    </div>
    <el-dialog :visible.sync="showSubscribeDialog" :modal="true" :show-close="false" :modal-append-to-body="true"
               custom-class="showSubscribeDialogStyle">
      <div style="position: relative;text-align: center">
        <div class="SubscribeDialogContent">
          <img src="../../assets/icon_subscribe_success.png" style="width: 167px;height: 165px">
          <p style="font-weight: 500;color: #292B2F;font-size: 28px;padding: 0;margin-top: 35px;  font-family: Ubuntu-Medium;">
            Subscription
            succeeded</p>
          <div class="btn-subscribe-success" @click="showSubscribeDialog=false">OK</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showSubscribeInfoDialog" width="100%" :modal="true" :show-close="false"
               :modal-append-to-body="true"
               custom-class="showSubscribeInfoDialogStyle">
      <div style="position: relative;text-align: center">
        <div class="SubscribeInfoDialogContent">
          <div class="SubscribeInfoDialogContentMain">
            <div style="margin-left: 250px;height: 100%;text-align: center;display: flex;flex-direction: column;align-items: center;justify-content: center">
              <div style="color: #F0B45A;font-size: 30px;font-weight: bold;font-family: Ubuntu-Medium">Subscribe Info</div>
              <input class="root-input" v-model="subscribeInfo.email" placeholder="Email" style="margin-top: 40px"></input>
              <input class="root-input" v-model="subscribeInfo.organization" placeholder="Organization" style="margin-top: 30px"></input>
              <input class="root-input" v-model="subscribeInfo.country" placeholder="Country" style="margin-top: 30px"></input>
              <input class="root-input" v-model="subscribeInfo.last_name" placeholder="LastName" style="margin-top: 30px"></input>
              <input class="root-input" v-model="subscribeInfo.given_name" placeholder="GivenName" style="margin-top: 30px"></input>
              <div style="margin-top: 50px;display: flex;flex-direction: row">
                <div @click="showSubscribeInfoDialog=false" class="root-btn-v1">Cancel</div>
                <div @click="doSubscribe" class="root-btn-v1" style="border-color: #FCB244;margin-left: 80px;background-color: #FCB244;color: #FEFEFE">Confirm</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import '../../assets/common/font.css'
import {getUserId} from "../../utils/store";
import {subscribeIEONews} from '../../api/ieo'
import {Loading} from 'element-ui'
export default {
  name: "index",
  data() {
    return {
      subscribeInfo: {
        email: '',
        organization: '',
        last_name: '',
        given_name: '',
        country: ''
      },
      showSubscribeInfoDialog: false,
      showSubscribeDialog: false,
      subscribe_text: ''
    }
  },
  mounted() {
  },
  methods: {
    isLogin() {
      var user_id = getUserId()
      if (user_id) {
        return true
      }
      return false
    },
    goLogin() {
      this.$router.push("/login")
    },
    goHome() {
      this.$router.push("/home")
    },
    doSubscribe() {
      let email = this.subscribeInfo.email
      let organization = this.subscribeInfo.organization
      let last_name = this.subscribeInfo.last_name
      let given_name = this.subscribeInfo.given_name
      let country = this.subscribeInfo.country
      if (email === '') {
        this.$message.warning("Please enter your email ")
        return
      }
      if (organization === '') {
        this.$message.warning("Please enter your Organization ")
        return
      }
      if (last_name === '') {
        this.$message.warning("Please enter your LastName ")
        return
      }
      if (given_name === '') {
        this.$message.warning("Please enter your GivenName ")
        return
      }
      if (country === '') {
        this.$message.warning("Please enter your Country ")
        return
      }
      let loadingInstance = Loading.service({fullscreen: true, background: 'rgba(0, 0, 0, 0.8)'})
      subscribeIEONews(email, last_name, given_name, organization, country).then((res) => {

        this.showSubscribeInfoDialog = false

        var result = res.data
        if (result && result.status === 'subscribed') {
          this.showSubscribeDialogSuccess()
        } else {
          var msg = ''
          if (result.title) {
            msg = result.title
          }
          if (msg == undefined || msg === '') {
            msg = 'Subscribe failed, Please try again'
          }
          this.$message.error(msg)
        }
        loadingInstance.close()
      })
    },
    showSubscribeDialogSuccess() {
      this.showSubscribeDialog = true
    }
  }
}
</script>

<style>
.showSubscribeInfoDialogStyle {
  width: 100%;
  text-align: center;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

.showSubscribeDialogStyle {
  width: 40%;
  text-align: center;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

</style>

<style lang="scss" scoped>

.root-btn-v1 {
  cursor: pointer;
  width: 176px;
  height: 60px;
  background: #FFFFFF;
  border: 2px solid #ABABAB;
  border-radius: 4px;
  font-size: 26px;
  font-family: Ubuntu-Medium;
  font-weight: 500;
  color: #ABABAB;
  line-height: 60px;
}


.root-input {
  box-sizing: border-box;
  border: 2px solid #F0B45A;
  color: #F0B45A;
  font-size: 22px;
  width: 400px;
  height: 50px;
  background-color: transparent;
  outline: none;
  border-radius: 4px;
  padding: 0 15px;
  font-family: Ubuntu-Medium;
}

.SubscribeInfoDialogContentMain {
  position: relative;
  text-align: center;
  background-color: transparent;
  width: 955px;
  height: 648px;
  margin: 0 auto;
  background-image: url("../../assets/bg_subscribe_info.png");
  background-repeat: no-repeat;
}


.SubscribeInfoDialogContent {
  position: relative;
  background-color: transparent;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-subscribe-success {
  cursor: pointer;
  margin-top: 80px;
  line-height: 70px;
  text-align: center;
  width: 370px;
  color: white;
  font-weight: bold;
  font-family: "Microsoft YaHei";
  font-size: 28px;
  height: 70px;
  background: #FCB244;
  border-radius: 10px;
}

.SubscribeDialogContent {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 45px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-subscribe {
  cursor: pointer;
  text-align: center;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #FCB244;
  color: white;
  font-weight: 500;
  font-size: 26px;
  height: 100%;
  padding: 0 18px;
  line-height: 64px;
  font-family: Ubuntu-Medium;
}

.footer {
  line-height: 33px;
  text-align: center;
  width: 100%;
  height: 33px;
  background-color: #000000
}

.nav-title {
  cursor: pointer;
  border-radius: 10px;
  background-color: #292B2F;
  color: white;
  font-size: 28px;
  font-family: 'PingFang SC';
  padding: 14px 20px
}

.root-container {
  width: 100%;
  background-image: url("../../assets/root_bg.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  //background-position: 100% 100%;
}

.info_text {
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: #FDFDFD;
  font-size: 26px;
  line-height: 40px;
  font-family: Ubuntu-Medium;
}

.subscribe1 {
  position: relative;
  height: 64px;
  text-align: center;
  width: 392px;
  line-height: 64px;
  background-color: #F0B45A;
  font-size: 25px;
  color: #292B2F;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-family: Ubuntu-Medium;

  &:after {
    border: 32px solid transparent;
    border-left: 32px solid #F0B45A;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: -64px;
    content: ' '
  }
}
</style>