<template>
  <div class="iloveieo-root" style="height: 88vh;width: 100%;">
    <div v-if="!isLogin" class="iloveieo-content">
      <div style="width: 100%;height: 100%;text-align: left">
        <p style="color: #F5AF3C;font-size: 54px;font-weight: 600">Homepage Content</p>
      </div>

    </div>
    <div v-if="isLogin" style="width: 100%;height: 88vh;">
      <el-container style="margin: 0;padding: 0">
        <el-aside width="330px" class="aside">
          <el-row class="tac" style="background-color: #F0B45A;height: 88vh;padding: 20px 0 0 0 ;margin: 0;overflow-y: hidden">
            <el-col>
              <el-menu :router="true" default-active="1">
                <el-menu-item index="1" :route="{path:'economic'}">
                  <div class="menu-content">
                    <div class="menu-icon1"></div>
                    <p class="menu-title">Economics</p>
                  </div>
                </el-menu-item>
                <el-menu-item index="2" :route="{path:'financial'}">
                  <div class="menu-content">
                    <div class="menu-icon2"></div>
                    <p class="menu-title">Financial Literacy</p>
                  </div>
                </el-menu-item>
                <el-menu-item index="3" :route="{path:'businesscast'}">
                  <div class="menu-content">
                    <div class="menu-icon3"></div>
                    <p class="menu-title">Business
                      Case</p>
                  </div>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <el-main class="main-root-container">
          <el-container>
            <router-view></router-view>
          </el-container>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import {getUserId} from '../../utils/store'
import '../../assets/common/font.css'
export default {
  name: "Home",
  computed: {
    isLogin: function () {
      var user_id = getUserId()
      if (user_id) {
        return true
      }
      return false
    }
  },
  mounted() {
  },
  methods: {}
}
</script>


<style lang="scss" scoped>

@import "../../assets/common/common.css";

.main-root-container {
  height: 88vh;overflow-y: scroll;width: 100%;
  background-image: url("../../assets/home_bg.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
}

.container {

}

.el-menu-item.is-active {
  background-color: white !important;

  .menu-title {
    color: #F0B45A;
  }

  .menu-icon1 {
    background-image: url("../../assets/economic_select.png");
  }

  .menu-icon2 {
    background-image: url("../../assets/financial_select.png");
  }

  .menu-icon3 {
    background-image: url("../../assets/businesscast_select.png");
  }
}

/deep/ .el-menu {
  border-right: solid 0px transparent;
}

/deep/ .el-menu-item {
  border-color: transparent;
  padding: 0;
  margin: 0;
  height: 100px;
  line-height: 100px;
  background-color: #F0B45A;
  color: white;
  font-size: 24px;
  border-color: transparent;

  &:hover {
    background-color: white;
    color: #F0B45A;

    .menu-icon1 {
      background-image: url("../../assets/economic_select.png");
    }

    .menu-icon2 {
      background-image: url("../../assets/financial_select.png");
    }

    .menu-icon3 {
      background-image: url("../../assets/businesscast_select.png");
    }
  }

  .menu-title {
    font-family: Ubuntu-Medium;
    margin: 0 0 0 15px;
    padding: 0;
  }

  .menu-icon1 {
    width: 44px;
    height: 32px;
    object-fit: fill;
    background-image: url("../../assets/economic_unselect.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .menu-icon2 {
    width: 34px;
    height: 33px;
    object-fit: fill;
    background-image: url("../../assets/financial_unselect.png");
    background-repeat: no-repeat;
    background-size: cover
  }

  .menu-icon3 {
    width: 38px;
    height: 34px;
    object-fit: fill;
    background-image: url("../../assets/businesscast_unselect.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

}


.menu-content {
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 15px;
  flex-direction: row
}

.el-main {
  margin: 0px;
  padding: 0px;
}

.el-container {
  margin: 0px;
  padding: 0px;
}

.home_container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
}

</style>