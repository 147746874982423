<template>
  <div class="register-root">
    <div style="position: absolute;width: 100%;margin-top: 80px;">
      <div class="register-content">
        <div>
          <div>
            <p class="register-label">邮箱</p>
            <input class="register-input" placeholder="请输入账号绑定的邮箱"></input>
          </div>
          <div style="margin-top: 15px">
            <p class="register-label">密码</p>
            <input class="register-input" placeholder="请输入账号密码"></input>
          </div>
          <div style="margin-top: 15px">
            <p class="register-label">姓名</p>
            <input class="register-input" placeholder="请输入个人姓名"></input>
          </div>
          <div style="margin-top: 15px">
            <p class="register-label">国家</p>
            <el-select class="registerSelect" popper-append-to-body="false" v-model="ruleForm.country">
              <el-option value="中国">中国</el-option>
              <el-option value="美国">美国</el-option>
              <el-option value="英国">英国</el-option>
            </el-select>
          </div>

          <div style="display: flex;flex-direction: row;position: relative;margin-top: 15px">
            <div>
              <p class="register-label">国家</p>
              <el-select class="registerSelect1" popper-append-to-body="false" v-model="ruleForm.type">
                <el-option value="学生">学生</el-option>
                <el-option value="老师">老师</el-option>
              </el-select>
            </div>
            <div style="position:absolute;right: 0">
              <p class="register-label">大学入学时间</p>
              <el-select class="registerSelect2" popper-append-to-body="false" v-model="ruleForm.graduation_year">
                <el-option value="2021">2011</el-option>
                <el-option value="2022">2022</el-option>
              </el-select>
            </div>
          </div>
        </div>

        <p class="register-login-btn" @click="submitForm('ruleForm')" style="margin-top: 80px"><span>确认注册</span></p>
        <div
            style="padding-bottom: 30px;display: flex;flex-direction: row;align-items: center;justify-content: center; width: 40%;margin-top: 1px">
          <img id="checkbox" @click="changeAgrees" src="../../assets/checkbox_unselected.png"
               style="width: 1.3vw;height: 1.3vw">
          <p style="margin-left: 0.3vw; display: inline-block;color: #9F9F9F; font-size: 0.8vw">我已阅读并同意</p>
          <p @click="goProtocolView" class="protocol">《用户隐私协议》</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Register",
  data() {
    return {
      img_checkbox_unselected: require('../../assets/checkbox_unselected.png'),
      img_checkbox_selected: require('../../assets/checkbox_selected.png'),
      agree: false,
      ruleForm: {
        email: '',
        pwd: '',
        name: '',
        country: '',
        type: '',
        graduation_year: ''
      },
      rules: {
        email: [
          {required: true, message: '请输入账号绑定的邮箱', trigger: 'blur'}
        ],
        pwd: [
          {required: true, message: '请输入账号密码', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入个人姓名', trigger: 'blur'}
        ],
        country: [
          {required: true, message: '请选择', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择', trigger: 'blur'}
        ],
        graduation_year: [
          {required: true, message: '请选择', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
  },
  methods: {
    goProtocolView() {
      this.$router.push('/protocol')
    },
    changeAgrees() {
      var imag = document.getElementById('checkbox')
      var temp = this.agree
      if (temp) {
        imag.src = this.img_checkbox_unselected
      } else {
        imag.src = this.img_checkbox_selected
      }
      this.agree = !temp
    },
    submitForm(formName) {
      this.$message({
        message: '正在开发中',
        type: 'warning'
      })
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     if (!this.agree) {
      //       this.$message({
      //         message: '请先确认用户协议！',
      //         type: 'error'
      //       })
      //       return
      //     }
      //     if (this.ruleForm.pwd !== this.ruleForm.pwd1) {
      //       this.$message({
      //         message: '两次密码不一致！',
      //         type: 'error'
      //       })
      //     } else {
      //       const loadingInstance = Loading.service({fullscreen: true, text: '正在提交数据，请稍等...'})
      //       registerStudent(this.ruleForm).then((res) => {
      //         loadingInstance.close()
      //         var result = res.data
      //         if (result && result.code === -1) {
      //           this.$message({
      //             message: result.msg,
      //             type: 'error'
      //           })
      //         } else {
      //           this.$message({
      //             message: '注册成功，请尽快去打开你的邮箱进行验证吧！',
      //             type: 'success'
      //           })
      //           this.$router.replace('/login')
      //         }
      //       })
      //     }
      //   } else {
      //     return false;
      //   }
      // });
    },
  }
}
</script>
<style>
.el-form-item {
  margin-bottom: 2vw;
}
</style>

<style lang="scss" scoped>

@import "../../assets/common/common.css";

.register-label {
  display: inline-block;
  color: #333333;
  font-size: 1.2vw;
  font-weight: 400;
  font-family: "Microsoft YaHei";
}

.register-input {
  box-sizing: border-box;
  margin-left: 20px;
  border: 1px solid #FF9900;
  color: #FF9900;
  font-size: 1.1vw;
  width: 700px;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 6px;
  padding: 0 15px;
}

.registerSelect {
  margin-left: 20px;
  border: 1px solid #FF9900;
  color: #FF9900;
  font-size: 1.1vw;
  width: 700px;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 6px;
  padding: 0px;

  /deep/ .el-input__inner {
    background-color: transparent;
    height: 3vw;
    outline: none;
    border: transparent;
    color: #FF9900;
    font-size: 1.1vw;
  }

  /deep/ .el-select-dropdown__item {
    color: #FF9900;
    font-size: 1.1vw;
  }
}


.registerSelect1 {
  margin-left: 20px;
  border: 1px solid #FF9900;
  color: #FF9900;
  font-size: 1.1vw;
  width: 230px;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 6px;
  padding: 0px;

  /deep/ .el-input__inner {
    background-color: transparent;
    height: 3vw;
    outline: none;
    border: transparent;
    color: #FF9900;
    font-size: 1.1vw;
  }

  /deep/ .el-select-dropdown__item {
    color: #FF9900;
    font-size: 1.1vw;
  }
}

.registerSelect2 {
  margin-left: 20px;
  border: 1px solid #FF9900;
  color: #FF9900;
  font-size: 1.1vw;
  width: 230px;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 6px;
  padding: 0px;

  /deep/ .el-input__inner {
    background-color: transparent;
    height: 3vw;
    outline: none;
    border: transparent;
    color: #FF9900;
    font-size: 1.1vw;
  }

  /deep/ .el-select-dropdown__item {
    color: #FF9900;
    font-size: 1.1vw;
  }
}

input::-webkit-input-placeholder {
  color: #FF9900;
}

.register-root {
  background-image: url("../../assets/bg.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  text-align: center;
}


.protocol {
  &:hover {
    cursor: pointer;
  }

  display: inline-block;
  color: #7E99E8;
  font-size: 0.8vw
}

.register-content {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>