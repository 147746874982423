<template>
  <div style="text-align: center">
    <div
        style="max-width: 1200px;margin: 2vw auto;text-align: left;background-color: #F7F7F7;padding: 0.1vw 1vw;box-sizing: border-box">
      <p class="protocol-title">IloveIEO.com User Agreement</p>
      <p class="protocol-content">Welcome to IloveIEO (IloveIEO.com) (hereinafter referred to as "the Site"). Please read the following terms and conditions carefully, and if you have any objection to any of the terms of this Agreement, you may choose not to access the Site in the first instance. By registering for an account and logging in successfully, you (the User) are deemed to have fully accepted all of the terms and conditions of this Agreement. If any provision of this Agreement is, for any reason, wholly or partially invalid or unenforceable, the remaining provisions of this Agreement shall remain valid and binding.</p>

      <p class="protocol-title" style="margin-top: 2vw">Privacy Policy and Privacy Protection</p>
      <p class="protocol-content">1. This website guarantees not to disclose or provide to third parties the registration information of individual users and the non-public content (including access data, website usage data, etc.) stored on the website when users use this website
        2. This site attaches great importance to the protection of your privacy, sometimes we need certain information to provide you with the services you request, including your country, e-mail address, name, employer, job title, etc. Without disclosing the privacy information of individual users, we have the right to process the above information to the extent permitted by law (including but not limited to user group profile, website operation statistics, etc.).
        3. By using or continuing to use our services, you consent to our collection, use, storage, and sharing of your relevant information. Of course, we will only collect information that is necessary to achieve the functionality of our products and will limit our collection to the minimum necessary to achieve the purpose of your normal use of our website, and will not over-collect other personal information about you.
        4. You are fully aware and understand that if you do not consent to our collection and processing of your necessary personal information or withdraw your previous consent, we will not be able to continue to provide you with products or services because it is necessary for us to provide you with such products or services.
        The personal information we have collected under this Agreement will be retained for a period of three years after we have terminated our services to you.</p>

      <p class="protocol-title" style="margin-top: 2vw">Terms of Use</p>
      <p class="protocol-content">1. Users shall be fully responsible for the authenticity, legality, and validity of all information provided to us in the process of registering an account on this website. Users shall not impersonate others; shall not use the name of others to publish any information; shall not maliciously use the account to cause misidentification by other users.
        2. Users may not transfer or lend their accounts to others. If you find that your account is being used illegally by others, you should notify us immediately. The website will not be responsible for any illegal use of the account caused by hacking or the user's negligence in storage.
        3. Without our written consent, you may not grant your own permission to a third party to access, copy, distribute, exhibit, adapt, compile, publish, transmit, reproduce, edit, modify, rent, sell, or otherwise create derivatives of the academic content and materials on this website in any form. Intellectual property rights owned and enjoyed by us do not transfer rights as a result of any use by you.
        If the user violates any of the above provisions when using the website services, the website has the right to request the user to correct or directly take all necessary measures (including but not limited to suspending or terminating the user's right to use the website services, withdrawing his registered account, etc.) to mitigate the impact caused by the user's misconduct.</p>

      <p class="protocol-title" style="margin-top: 2vw">Disclaimers</p>
      <p class="protocol-content">This website does not guarantee the accuracy and completeness of external links set up to provide convenience to users.  At the same time, the website does not assume any responsibility for the content on any web pages pointed to by such external links that are not under the actual control of the website. </p>

      <p class="protocol-title" style="margin-top: 2vw">Modification of Agreement</p>
      <p class="protocol-content">1. According to the development of the Internet and changes in relevant laws, regulations, and regulatory documents, or due to business development needs, we have the right to make modifications or changes to the terms of this agreement, once the content of this agreement changes, we will directly publish the revised agreement on this website, the publication behavior is considered that the website has notified the user of the revised content. The website may also use e-mail and other transmission methods to remind users of the modification of the terms of the agreement, service changes, or other important matters.
        2. If the user does not agree with the changes made to the terms and conditions of this agreement, the user has the right to stop using the services of the website. If the user continues to use the website service, the user is deemed to accept the modifications made to the terms and conditions of this agreement.
        3. All notices from this website to users under this agreement can be made through web announcements and emails; such notices are deemed to have been delivered to the addressee on the date of sending.
        4. The user's notice to the Website shall be delivered through the contact information such as mailing address and e-mail address officially published on the Website.</p>

<!--      <p class="protocol-title" style="margin-top: 2vw">协议修改</p>-->
<!--      <p class="protocol-content">1. 根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，本网站有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，本网站将会直接在网站上公布修改之后的协议内容，该公布行为视为本网站已经通知用户修改内容。本网站也可采用电子邮件等传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。2. 如果不同意本网站对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受本网站对本协议相关条款所做的修改。3. 本协议项下本网站对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。4. 用户对于本网站的通知应当通过网站对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</p>-->

<!--      <p class="protocol-title" style="margin-top: 2vw">其他规定</p>-->
<!--      <p class="protocol-content">1. 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向本网站所在地的人民法院提起诉讼。2. 本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。3. 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。4. 本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>-->


<!--      <p class="protocol-title" style="margin-top: 2vw">隐私政策</p>-->
<!--      <p class="protocol-content">-->
<!--        本网站非常重视对您的个人隐私保护，有时候我们需要某些信息才能为您提供您请求的服务，本隐私政策（以下简称“本政策”）解释了这些情况下的数据收集和使用情况。本政策适用于本网站的所有相关服务，随着本网站服务范围的扩大，隐私政策的内容可由本网站随时更新，且毋须另行通知。更新后的隐私政策一旦在网页上公布即有效代替原来的隐私政策。在使用本网站各项服务前，请您务必仔细阅读并透彻理解本政策，并做出您认为适当的选择。一旦您开始使用本网站的各项服务，即表示您已充分理解并同意本政策。需要提醒您注意，本隐私政策仅适用于我们所收集的信息，不适用于通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息以及通过在我们服务中进行广告服务的其他公司或机构所收集的信息。我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。您在使用我们服务时主动提供的信息：您通过平台登录账号时所使用的手机号码和邮箱地址及其他账号资料信息等。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。</p>-->
    </div>
  </div>
</template>

<script>

import '../../assets/common/font.css'

export default {
  name: "Protocol"
}
</script>

<style lang="scss" scoped>

.protocol-title {
  color: #333333;
  font-size: 1vw;
  font-weight: bold;
  font-family: Ubuntu-Medium
}

.protocol-content {
  color: #333333;
  font-size: 1vw;
  line-height: 2vw;
  font-family: Ubuntu-Medium

}

</style>