<template>
  <div>
<!--    <div class="go-footer">-->
<!--      <div>Gocademy 版权所有 © 2021 | contact@gocademy.cn ｜ http://home.gocademy.cn |</div>-->
<!--      <div style="margin-top: 10px">学析教育科技（上海）有限公司</div>-->
<!--      <div style="margin-top: 30px">-->
<!--        <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021027051号-1</a>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
name: "CommonQuestion"
}
</script>

<style scoped>


.go-footer {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-top: 100px;
  padding-bottom: 50px;
  color: #50555c;
}

</style>