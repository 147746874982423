import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/index.vue'
import Home from '../views/home/index.vue'
import root from '../views/root/index.vue'
import Register from '../views/register/index.vue'
import CommonQuestion from '../views/commonquestion/index.vue'
import Protocol from '../views/protocol/index.vue'
import ForgetPwD from '../views/forgetpwd/index.vue'
import economic from '../views/economic.vue'
import financial from '../views/financial.vue'
import businesscast from '../views/businesscast.vue'
import {getUserId} from "@/utils/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'root',
        component: root,
    },
    {
        path: '/home',
        name: 'Home',
        redirect: '/home/economic',
        component: Home,
        children: [
            {
                path: 'businesscast',
                component: businesscast,
                name: 'businesscast',
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'financial',
                component: financial,
                name: 'financial',
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'economic',
                component: economic,
                name: 'economic',
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/commonquestion',
        name: 'CommonQuestion',
        component: CommonQuestion
    },
    {
        path: '/protocol',
        name: 'Protocol',
        component: Protocol
    },
    {
        path: '/resetpwd',
        name: 'ForgetPwD',
        component: ForgetPwD
    },
]
const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        var userId = getUserId()
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router