<template>
  <div class="register-root">
    <div style="position: absolute;width: 100%;margin-top: 120px">
      <div class="register-content">
        <div>
          <div style="display: flex;flex-direction: row;align-items: center">
            <p class="register-label">Email</p>
            <input class="register-input" placeholder="Please enter your email" v-model="ruleForm.student_email"></input>
          </div>
          <div style="margin-top: 15px;display: flex;flex-direction: row;align-items: center">
            <p class="register-label">Password</p>
            <input class="register-input" placeholder="Please enter your password" v-model="ruleForm.student_pwd"></input>
          </div>
          <div style="margin-top: 15px;display: flex;flex-direction: row;align-items: center">
            <p class="register-label">Type</p>
            <el-select class="registerSelect" popper-append-to-body="false" v-model="ruleForm.type">
<!--              <el-option value="Student">Student</el-option>-->
              <el-option value="Coordinator">Coordinator</el-option>
            </el-select>
          </div>
        </div>
        <p class="register-login-btn" @click="submitForm('ruleForm')" style="margin-top: 150px;font-family: Ubuntu-Medium"><span>Login</span></p>
        <div
            style="margin-bottom: 30px;display: flex;flex-direction: row;align-items: center;justify-content: center; width: 40%;margin-top: 1px">
          <p @click="goProtocolView" class="protocol">User Terms & Conditions</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {loginByCoordinator, loginByStudent} from '../../api/ieo'
import {setUserId, setUserType,setUserGivenName,setUserLastName} from "../../utils/store";
// ceshi123@qq.com impact
export default {
  name: "Login",
  data() {
    return {
      agree: true,
      img_checkbox_unselected: require('../../assets/checkbox_unselected.png'),
      img_checkbox_selected: require('../../assets/checkbox_selected.png'),
      ruleForm: {
        student_email: '',
        student_pwd: '',
        type: 'Coordinator'
      },
      rules: {
        student_email: [
          {required: true, message: 'Please enter your email', trigger: 'blur'}
        ],
        student_pwd: [
          {required: true, message: 'Please enter your password', trigger: 'blur'}
        ],
        type: [
          {required: true, message: 'Please select your type', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
  },
  methods: {
    resetPwd() {
      this.$router.push('/resetpwd')
    },
    goProtocolView() {
      this.$router.push('/protocol')
    },
    changeAgrees() {
      var imag = document.getElementById('checkbox')
      var temp = this.agree
      if (temp) {
        imag.src = this.img_checkbox_unselected
      } else {
        imag.src = this.img_checkbox_selected
      }
      this.agree = !temp
    },
    submitForm(formName) {
      var email = this.ruleForm.student_email
      if (email === '') {
        this.$message({
          message: 'Please enter your email',
          type: 'warning'
        })
        return
      }
      var pwd = this.ruleForm.student_pwd

      if (pwd === '') {
        this.$message({
          message: 'Please enter your password',
          type: 'warning'
        })
        return
      }
      var type = this.ruleForm.type
      if (type === '') {
        this.$message({
          message: 'Please select your type',
          type: 'warning'
        })
        return
      }
      if (!this.agree) {
        this.$message({
          message: 'Please agree the Terms & Conditions',
          type: 'error'
        })
        return
      }
      if (type === 'Student') {
        var params = {student_email: email, student_pwd: pwd}
        loginByStudent(params).then((res) => {
          var result = res.data
          if (result.code === 0) {
            var user_id = result.data._id.$id
            var user_given_name = result.data.student_givenName
            var user_last_name = result.data.student_lastName
            setUserGivenName(user_given_name)
            setUserLastName(user_last_name)
            setUserId(user_id)
            setUserType('Student')
            this.$message({
              message: 'Login Success',
              type: 'success'
            })
            this.$router.replace({path: '/home'})
          } else {
            this.$message({
              message: result.msg,
              type: 'error'
            })
          }
        })
      } else {
        var params = {contact_email: email, contact_pwd: pwd}
        loginByCoordinator(params).then((res) => {
          var result = res.data
          if (result.code === 0) {
            var user_id = result.data._id.$id
            setUserId(user_id)
            setUserType('Coordinator')
            this.$message({
              message: 'Login Success',
              type: 'success'
            })
            this.$router.replace({path: '/home'})
          } else {
            this.$message({
              message: result.msg,
              type: 'error'
            })
          }
        })
      }
    },
  }
}
</script>
<style>
</style>

<style lang="scss" scoped>

@import "../../assets/common/common.css";
@import "../../assets/common/font.css";
.registerSelect {
  font-family: Ubuntu-Medium;
  margin-left: 10px;
  border: 1px solid #FF9900;
  color: #FF9900;
  font-size: 1.1vw;
  width: 700px;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 6px;
  padding: 0px;

  /deep/ .el-input__inner {
    background-color: transparent;
    height: 3vw;
    outline: none;
    border: transparent;
    color: #FF9900;
    font-size: 1.1vw;
  }

  /deep/ .el-select-dropdown__item {
    color: #FF9900;
    font-size: 1.1vw;
  }
}


.protocol {
  &:hover {
    cursor: pointer;
  }
  font-family: Ubuntu-Medium;
  display: inline-block;
  color: #7E99E8;
  font-size: 0.8vw
}


.register-label {
  font-family: Ubuntu-Medium;
  width: 130px;
  text-align: left;
  color: #333333;
  font-size: 1.2vw;
  font-weight: 400;
}

.register-input {
  flex: 1;
  font-family: Ubuntu-Medium;
  box-sizing: border-box;
  margin-left: 10px;
  border: 1px solid #FF9900;
  color: #FF9900;
  font-size: 1.1vw;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 6px;
  padding: 0 15px;
}

input::-webkit-input-placeholder {
  color: #FF9900;
}

.register-root {
  font-family: Ubuntu-Medium;
  background-image: url("../../assets/bg.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  text-align: center;
}


.forgetpwd {
  text-align: right;
  color: #5459A6;
  font-size: 0.8vw;
  margin-top: -1.2vw;

  &:hover {
    cursor: pointer;
  }
}

.register-content {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}


</style>