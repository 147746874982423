import request from "../utils/request";

export function loginByStudent(params) {
    return request.post('./index.php', {
        function: "loginByStudent",
        student_email: params.student_email,
        student_pwd: params.student_pwd
    })
}

export function loginByCoordinator(params) {
    return request.post('./index.php', {
        function: "loginByCoordinator",
        contact_email: params.contact_email,
        contact_pwd: params.contact_pwd
    })
}

export function getDataListByBU(project_BU, event) {
    return request.post('./index.php', {
        function: "getDataListByBU",
        project_BU,
        event
    })
}
export function subscribeIEONews(email, lastName,givenName,organization,country) {
    return request.post('./index.php', {
        function: "subscribeIEONews",
        email,
        lastName,
        givenName,
        country,
        organization
    })
}
export function functionLog(_function, user_id,file_name) {
    return request.post('./index.php', {
        function: "functionLog",
        save_function:_function,
        user_id,
        file_name
    })
}