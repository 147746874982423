<template>
  <div class="iloveieo-content" style="margin: 20px;">
    <div  v-if="haveData" v-for="item in dataList" :key="item._id.$id">
      <div class="item_style">
        <div style="display: flex;flex-direction: row;">
          <div style="width: 12px;height: 12px;background-color: #F0B45A;border-radius: 50%;margin-top: 3px"></div>
          <div style="margin-left: 12px;display: flex;flex-direction: column;flex: 1">
            <div style="color: #F0B45A;font-size: 25px;font-weight: 500;font-family: Ubuntu-Medium;line-height: 40px;margin-top: -10px">{{
                item.name
              }}
            </div>
            <div
                v-if="item.intro"
                class="item_intro">
              {{ item.intro }}
            </div>
            <div style="text-align: right;margin-top: 25px">
              <div @click="gotoLink(item.link,item.name)"
                 style="cursor: pointer;color: #333333;font-size: 25px;font-weight: 400;text-decoration: none">
                <div
                    style="display: inline;background-color: #F0B45A;border-radius: 19px;padding: 4px 28px;color: white;font-size: 20px;font-family: Ubuntu-Medium;font-weight: bold">
                  View
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!haveData">
      <p style="color: white;font-size: 40px;margin-top: 50px;text-align: center;font-family: Ubuntu-Medium">Coming soon... </p>
    </div>
  </div>
</template>
<script>

import '../assets/common/font.css'

import {getDataListByBU, functionLog} from "../api/ieo";
import {getUserId} from "../utils/store";

export default {
  name: "economic",
  data() {
    return {
      haveData:false,
      dataList: []
    }
  },
  mounted() {
    console.log('---mounted---')
    this.fetchData()
  },
  beforeCreate() {
    console.log('---beforeCreate---')
  },
  methods: {
    gotoLink(link,name){
      window.open(link,"_blank")
      var user_id = getUserId()
      functionLog("download",user_id,name).then((res)=>{
      })
    },
    fetchData() {
      getDataListByBU("IEO", "Economics").then((res) => {
        this.dataList = res.data.data
        if(this.dataList && this.dataList.length >0) {
          this.haveData = true
        }
      })
    }
  }
}
</script>

<style scoped>

@import "../assets/common/common.css";

.item_intro {
  color: #999;font-size: 18px;font-family: Ubuntu-Light;margin-top: 0px;line-height: 28px;margin-left: 10px;font-weight: normal;
}
.item_style {
  background-color: white;
  text-align: left;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  border-color: #F6F6F6;
  border-style: solid;
  border-width: 1px
}


</style>