<template>
  <div style="width: 100%;height: 100%;position: relative;">
    <div style="position: absolute;width: 100%;background-color: white;margin-top: 3vw">
      <div class="register-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" style="width: 30%">
          <el-form-item prop="student_email">
            <el-input v-model="ruleForm.student_email" placeholder="请输入你的邮箱" style="font-size: 1.0vw"></el-input>
          </el-form-item>
          <el-form-item prop="student_pwd_new">
            <el-input v-model="ruleForm.student_pwd_new" placeholder="请输入你的新密码" show-password
                      style="font-size: 1.0vw"></el-input>
          </el-form-item>
          <el-form-item prop="student_pwd_new_again">
            <el-input v-model="ruleForm.student_pwd_new_again" placeholder="请再次输入你的新密码" show-password
                      style="font-size: 1.0vw"></el-input>
          </el-form-item>
        </el-form>
        <p class="register-login-btn" @click="submitForm('ruleForm')"><span>确认信息</span></p>
      </div>
    </div>
  </div>
</template>
<script>
// import {forgetPwd} from '../../api/gocademy'
import {Loading} from "element-ui";
export default {
  name: "ForgetPwD",
  data() {
    return {
      ruleForm: {
        student_email: '',
        student_pwd_new: '',
        student_pwd_new_again: '',
      },
      rules: {
        student_email: [
          {required: true, message: '请输入你的邮箱', trigger: 'blur'}
        ],
        student_pwd_new: [
          {required: true, message: '请输入你的新密码', trigger: 'blur'}
        ],
        student_pwd_new_again: [
          {required: true, message: '请再次输入你的新密码', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
  },
  methods: {
    submitForm(formName) {
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     var new_pwd = this.ruleForm.student_pwd_new
      //     var new_pwd_again = this.ruleForm.student_pwd_new_again
      //     if (new_pwd !== new_pwd_again) {
      //       this.$message({
      //         message: '两次密码不一致！',
      //         type: 'error'
      //       })
      //       return
      //     }
      //     const loadingInstance = Loading.service({fullscreen: true, text: '正在提交数据，请稍等...'})
      //     forgetPwd(this.ruleForm.student_email, this.ruleForm.student_pwd_new).then((res) => {
      //       loadingInstance.close()
      //       var result = res.data
      //       if (result.code === 0) {
      //         this.$message({
      //           message: '修改成功！',
      //           type: 'success'
      //         })
      //         this.$router.replace({path: '/login'})
      //       } else {
      //         this.$message({
      //           message: result.msg,
      //           type: 'error'
      //         })
      //       }
      //     })
      //   } else {
      //     return false;
      //   }
      // });
    },
  }
}
</script>
<style>
</style>

<style lang="scss" scoped>

@import "../../assets/common/common.css";

.forgetpwd {
  text-align: right;
  color: #5459A6;
  font-size: 0.8vw;
  margin-top: -1.2vw;

  &:hover {
    cursor: pointer;
  }
}

.register-content {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}


</style>